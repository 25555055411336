@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Lexend+Deca:wght@100..900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.image-background {
  width: 300px;
  height: 300px;
  background-image: url('/src/asset/smartgoal/Why\ set\ goals.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.slick-arrow.slick-next:before {
  content: "";
}

.slick-arrow.slick-prev:before {
  content: "";
}

.slick-slide>div {
  margin: 0 10px;
}

.single-blur {
  background: rgba(255, 255, 255, 0.05);
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  box-shadow: inset 1px 1px 0.12px rgba(255, 255, 255, 0.4),
    1px 1px 3px rgba(0, 0, 0, 0.1); */
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  background-clip: padding-box;
  position: absolute;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
}

.lexend-deca-normal {
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 400;
}

.lexend-deca-bold {
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 700;
}

.inter-normal {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}

.inter-semi {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
}

::placeholder {
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 500;
}

.react-tooltip {
  z-index: 9999;
}

.recharts-legend-item {
  display: flex !important;
  align-items: center;
}
#questions-tooltip .react-tooltip-arrow{
  display: none !important;
}
.zoCursor > span{
  display: none !important;
  opacity: 0 !important;
}

.leaderBoardBg {
  background-image: url('asset/gamification/background.png');
  width: 100%;
  height: 100%;
  background-size: cover;
}
.carousel .slide img{
  width: 90% !important;
}
input[type="range"]::-webkit-slider-thumb{
  transform: scale(1.5);
}